import { Box, Center, Heading, VStack, useToast, Text } from '@chakra-ui/react';
import { ChatbotForm } from '@/components/Chatbot/v2/ChatbotForm';
import { Breadcrumbs } from '@/components/Breadcrumbs/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { useChatbot, useUpdateChatbot } from '@/lib/sdk/chatbot/queries';
import { Task, TaskChanges, TaskProperties } from '@/lib/sdk/chatbot/types';
import type { ChatbotFormData } from '@/components/Chatbot/v2/types';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useState } from 'react';
import { UpdateDialog } from '@/components/Chatbot/UpdateDialog';

export const EditChatbot = () => {
  const toast = useToast();
  const { orgId, chatbotId } = useParams();
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [updates, setUpdates] = useState<TaskChanges[]>([]);
  const updateChatbotMutation = useUpdateChatbot();
  const { data: chatbot, isLoading, error } = useChatbot(chatbotId || '');

  const breadcrumbItems = [
    { label: 'Dashboard', href: `/dashboard/${orgId}` },
    { label: 'Edit Chatbot' },
  ];

  const handleSubmit = async (data: ChatbotFormData) => {
    try {
      setIsUpdating(true);
      const response = await updateChatbotMutation.mutateAsync({
        id: chatbotId || '',
        mode: 'ui',
        data: {
          title: data.title,
          template: data.template,
          tasks: data.tasks,
        }
      });

      // Show update dialog if there are changes
      if (response.changes) {
        setUpdates(response.changes);
      } else {
        // If no changes, redirect immediately
        handleUpdateComplete();
      }

      toast({
        title: 'Chatbot updated successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error: any) {
      toast({
        title: 'Error updating chatbot',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleUpdateComplete = () => {
    setUpdates([]);
    navigate(`/dashboard/${orgId}`);
  };

  const formatTasks = (tasks: TaskProperties[]) : Task[] => {
    return tasks.map((task) => {
      return {
        id: task.id,
        task_id: task.id,
        name: task.name,
        description: task.description,
        type: task.task_type,
        data: task.task_type_metadata,
        actions: task.task_actions ? task.task_actions : [],
      };
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }
  
  if (error) {
    return (
      <Center minH="100vh" bg="#fffaf5">
        <Text color="red.500">Error loading chatbot: {error.message}</Text>
      </Center>
    );
  }

  return (
    <Box>
      <VStack align="stretch" spacing={6}>
        <Breadcrumbs items={breadcrumbItems} />
        
        <Heading size={{ base: "md", md: "lg" }}>
          Update Chatbot
        </Heading>

        <ChatbotForm 
          onSubmit={handleSubmit} 
          isLoading={isUpdating}  
          initialData={{
            title: chatbot?.title || '', 
            template: chatbot?.template || '',
            tasks: formatTasks(chatbot?.task_list || []),                
          }}
        />

        {/* Update Dialog */}
        <UpdateDialog
          isOpen={updates.length > 0}
          onClose={handleUpdateComplete}
          changes={updates || []}
        />
      </VStack>
    </Box>
  );
};

export default EditChatbot;