import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    Box,
    Text,
    Tooltip,
    CheckboxGroup,
    Checkbox,
    SimpleGrid,
  } from '@chakra-ui/react';
  import { MdDragIndicator, MdDelete } from 'react-icons/md';
  import { useSortable } from '@dnd-kit/sortable';
  import { CSS } from '@dnd-kit/utilities';
  import { TaskField } from './TaskField';
  import { UseFormReturn, Controller } from 'react-hook-form';
  import { ChatbotFormData } from './types';
  import { FormField } from '@/components/Modal/FormField';
  import { ActionMap } from '@/lib/sdk/chatbot/types';
  
  // Brown theme colors
  const COLORS = {
    border: '#8B7355',
    borderLight: '#A0522D',
    headerBg: '#DEB887',
    hoverBg: '#F5DEB3',
    text: '#4d3d2d',
  };
  

  
  interface TaskTableProps {
    fields: any[];
    form: UseFormReturn<ChatbotFormData>;
    errors: any;
    editingTaskId: string | null;
    onStartEditing: (id: string) => void;
    onStopEditing: () => void;
    onRemoveTask: (index: number) => void;
  }
  
  const SortableTableRow = ({
    task,
    index,
    form,
    errors,
    isEditing,
    onRemove,
  }: any) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: task.id });
  
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      opacity: isDragging ? 0.5 : 1,
      position: 'relative' as const,
      zIndex: isDragging ? 1 : 'auto',
    };
  
    return (
      <Tr
        ref={setNodeRef}
        style={style}
        bg={isDragging ? 'gray.50' : undefined}
        _hover={{ bg: COLORS.hoverBg }}
        role="group"
        borderBottom="1px solid"
        borderColor={COLORS.borderLight}
      >
        <Td 
          width="40px" 
          borderRight="1px solid" 
          borderColor={COLORS.borderLight}
          p={3}
        >
          <Box 
            cursor={isEditing ? 'not-allowed' : 'grab'} 
            {...(!isEditing && { ...attributes, ...listeners })}
            opacity={0}
            _groupHover={{ opacity: 1 }}
            transition="opacity 0.2s"
          >
            <MdDragIndicator size={24} color={COLORS.text} />
          </Box>
        </Td>
        <Td 
          borderRight="1px solid" 
          borderColor={COLORS.borderLight} 
          width="250px"
          p={3}
        >
          <FormField
            label=""
            name={`tasks.${index}.name`}
            register={form.register}
            error={errors?.tasks?.[index]?.name}
            required
            placeholder="Enter task name"
            maxLength={24}
            value={task.name}
          />
        </Td>
        <Td 
          borderRight="1px solid" 
          borderColor={COLORS.borderLight} 
          width="300px"
          p={3}
        >
          <FormField
            label=""
            name={`tasks.${index}.description`}
            register={form.register}
            error={errors?.tasks?.[index]?.description}
            type="text"
            placeholder="Enter task description"
            maxLength={72}
            value={task.description}
          />
        </Td>
        <Td 
          borderRight="1px solid" 
          borderColor={COLORS.borderLight}
          p={3}
        >
          <TaskField
            index={index}
            register={form.register}
            control={form.control}
            setValue={form.setValue}
            errors={errors}
          />
        </Td>
        <Td 
          borderRight="1px solid" 
          borderColor={COLORS.borderLight} 
          width="200px"
          p={3}
        >
          <Controller
            name={`tasks.${index}.actions`}
            control={form.control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <CheckboxGroup
                colorScheme="whatsapp"
                value={value}
                onChange={onChange}
              >
                <SimpleGrid columns={1} spacing={2}>
                  {Object.entries(ActionMap).map(([key, value]) => (
                    <Checkbox
                      key={key}
                      value={value}
                      borderColor={COLORS.text}
                      sx={{
                        '[data-checked]': {
                          borderColor: '#25D366',
                          backgroundColor: '#25D366',
                        }
                      }}
                    >
                      {key}
                    </Checkbox>
                  ))}
                </SimpleGrid>
              </CheckboxGroup>
            )}
          />
        </Td>
        <Td width="40px" p={3}>
          <Tooltip label="Delete task">
            <IconButton
              aria-label="Remove task"
              icon={<MdDelete />}
              onClick={() => onRemove(index)}
              variant="ghost"
              colorScheme="red"
              size="sm"
              opacity={0}
              _groupHover={{ opacity: 1 }}
            />
          </Tooltip>
        </Td>
      </Tr>
    );
  };
  
  export const TaskTable = ({
    fields,
    form,
    errors,
    editingTaskId,
    onStartEditing,
    onStopEditing,
    onRemoveTask,
  }: TaskTableProps) => {
  
    return (
      <Box 
        borderWidth="1px" 
        borderColor={COLORS.border}
        borderRadius="md" 
        overflow="hidden"
        boxShadow="sm"
      >
        <Table variant="simple">
          <Thead>
            <Tr bg={COLORS.headerBg}>
              <Th 
                width="40px" 
                borderRight="1px solid" 
                borderColor={COLORS.border}
                p={3}
              ></Th>
              <Th 
                borderRight="1px solid" 
                borderColor={COLORS.border} 
                width="250px"
                p={3}
              >
                <Text color={COLORS.text} fontWeight="semibold">Task Name</Text>
              </Th>
              <Th 
                borderRight="1px solid" 
                borderColor={COLORS.border} 
                width="300px"
                p={3}
              >
                <Text color={COLORS.text} fontWeight="semibold">Description</Text>
              </Th>
              <Th 
                borderRight="1px solid" 
                borderColor={COLORS.border}
                p={3}
              >
                <Text color={COLORS.text} fontWeight="semibold">Configuration</Text>
              </Th>
              <Th 
                borderRight="1px solid" 
                borderColor={COLORS.border} 
                width="200px"
                p={3}
              >
                <Text color={COLORS.text} fontWeight="semibold">Actions</Text>
              </Th>
              <Th width="40px" p={3}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {fields.map((task, index) => (
              <SortableTableRow
                key={task.id}
                task={task}
                index={index}
                form={form}
                errors={errors}
                isEditing={task.id === editingTaskId}
                onStartEditing={() => onStartEditing(task.id)}
                onStopEditing={onStopEditing}
                onRemove={() => onRemoveTask(index)}
              />
            ))}
          </Tbody>
        </Table>
      </Box>
    );
  };