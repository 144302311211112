import { VStack, Box, useBreakpointValue } from '@chakra-ui/react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { TaskItem } from './TaskItem';
import { TaskTable } from './TaskTable';
import { useTaskListState } from './hooks/useTaskListState';
import { UseFormReturn } from 'react-hook-form';
import { ChatbotFormData } from './types';
import { Xid } from 'xid-ts';
import { TaskType } from '@/lib/sdk/chatbot/types';

interface TaskListProps {
  form: UseFormReturn<ChatbotFormData>;
  errors: any;
}

export const TaskList = ({ form, errors }: TaskListProps) => {
  const {
    fields,
    editingTaskId,
    handleStartEditing,
    handleStopEditing,
    handleReorder,
    handleRemoveTask,
  } = useTaskListState(form);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  // Default task template
  const createDefaultTask = (index: number) => ({
    id: new Xid().toString(),
    name: `Task ${index + 1}`,
    description: `Description for Task ${index + 1}`,
    type: TaskType.REGULAR,
    actions: [],
  });
  // Initialize default tasks if no fields exist
  if (fields.length === 0) {
    Array(5).fill(null).forEach((_, index) => {
      const defaultTask = createDefaultTask(index);
      form.setValue(`tasks.${index}`, defaultTask);
    });
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Prevent drag start while editing
      activationConstraint: {
        distance: editingTaskId ? Infinity : 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex(task => task.id === active.id);
      const newIndex = fields.findIndex(task => task.id === over.id);
      handleReorder(oldIndex, newIndex);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={fields.map(task => task.id)}
        strategy={verticalListSortingStrategy}
      >
        <Box width="100%" overflowX={isDesktop ? "auto" : "visible"}>
          {isDesktop ? (
            <TaskTable
              fields={fields}
              form={form}
              errors={errors}
              editingTaskId={editingTaskId}
              onStartEditing={handleStartEditing}
              onStopEditing={handleStopEditing}
              onRemoveTask={handleRemoveTask}
            />
          ) : (
            <VStack spacing={4} align="stretch">
              {fields.map((task, index) => (
                <TaskItem
                  key={task.id}
                  id={task.id}
                  index={index}
                  register={form.register}
                  control={form.control}
                  setValue={form.setValue}
                  remove={() => handleRemoveTask(index)}
                  errors={errors}
                  isEditing={task.id === editingTaskId}
                  onStartEditing={() => handleStartEditing(task.id)}
                  onStopEditing={handleStopEditing}
                />
              ))}
            </VStack>
          )}
        </Box>
      </SortableContext>
    </DndContext>
  );
};